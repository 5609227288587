<div class="page-layout-main">
  <app-header></app-header>

  <div class="layout-content">
    <div class="sidebar">
      <ng-content select="[page-sidebar]app-sidebar"></ng-content>
    </div>

    <ng-scrollbar class="page-layout-main-scrollbar" [viewClass]="'page-layout-main-viewport'">
      <div class="page-content">
        <ng-content select="[page-content]"></ng-content>
      </div>
    </ng-scrollbar>
  </div>
</div>
