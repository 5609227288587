<header class="header">
  <div class="header-navigation">
    <app-navigation-buttons></app-navigation-buttons>

    <a class="header-logo" (click)="navigateToDashboard()">
      <img class="logo" src="assets/images/logos/n-color-red-215x80.svg" alt="logo" />
    </a>
  </div>

  <ul class="header-links">
    <li class="connection-status">
      <app-device-connection-status-container></app-device-connection-status-container>
    </li>

    <li class="separator"></li>

    <li class="user" *ngIf="!!currentUserUsername">
      {{ currentUserUsername }}
    </li>

    <li class="user-options">
      <div ngbDropdown class="dropdown-toggle" placement="bottom-right">
        <button class="button-icon button-toggle-visibility" type="button" id="user-dropdown" ngbDropdownToggle>
          <span class="icon user-gray-icon"></span>
        </button>

        <div class="dropdown" ngbDropdownMenu aria-labelledby="user-dropdown">
          <button class="button gray" (click)="navigateToProfile()">
            <span class="icon user-settings-gray-24x24-icon"></span>

            <span class="button-label">{{ 'layout.header.dropdown.settings' | translate }}</span>
          </button>

          <button class="button gray" (click)="logoutEvent()">
            <span class="icon logout-gray-icon"></span>

            <span class="button-label">{{ 'layout.header.dropdown.logout' | translate }}</span>
          </button>
        </div>
      </div>
    </li>

    <li>
      <app-language-dropdown></app-language-dropdown>
    </li>

    <li>
      <app-support-button></app-support-button>
    </li>

    <li>
      <app-changelog-list-button></app-changelog-list-button>
    </li>
  </ul>
</header>
