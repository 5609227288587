<div class="sidebar" [class.is-closed]="isClosed" [class.is-hidden]="isHidden$ | async">
  <div class="sidebar-content">
    <ng-container *ngFor="let item of sidebarItems">
      <ng-container *appUserHasPermissionForMenuTab="item.name">
        <ng-container *ngIf="(isBpFlowEnabled && item.isEnabledWithBpFlow) || !isBpFlowEnabled">
          <button
            class="button gray"
            [class.is-active]="item.isActive && !isTutorialModalOpen"
            [routerLink]="item.url"
            (click)="redirectToPageOrOpenModal($event, item)"
          >
            <span class="icon {{ item.iconClass }}"></span>

            <span class="button-label">{{ item.label | translate }}</span>
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
    <button
      class="button gray"
      [class.is-active]="isTutorialModalOpen"
      *appUserHasPermissionForMenuTab="userRoleMenuTabName.tutorials"
      (click)="openTutorialModal()"
    >
      <span class="icon tutorials-gray-icon"></span>

      <span class="button-label">{{ 'layout.sidebar.menu.tutorials' | translate }}</span>
    </button>
  </div>

  <button class="button-icon gray toggle-button" (click)="toggleSidebar()">
    <span class="icon" [ngClass]="isClosed ? 'expand-gray-icon' : 'minimalize-gray-icon'"></span>
  </button>
</div>
