import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, UrlTree } from '@angular/router';

import { take } from 'rxjs/operators';
import { filter, from, Observable } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { SidebarService } from '../../services/sidebar/sidebar.service';
import { SidebarItemModel } from '../../models/sidebar-item/sidebar-item.model';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { NoFunctionalityService } from '../../../shared/services/no-functionality/no-functionality.service';
import { ModalService } from '../../../modal/services/modal.service';
import { TutorialModalComponent } from '../../../tutorials/components/tutorial-modal/tutorial-modal.component';
import { ModalSizeEnum } from '../../../modal/enums/modal-size.enum';
import { CurrentUserService } from '../../../users/services/current-user/current-user.service';
import { CurrentUserModel } from '../../../users/models/current-user/current-user.model';
import { UserRoleMenuTabNameEnum } from '../../../user-roles/enums/user-role-menu-tab-name/user-role-menu-tab-name.enum';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends SubscriptionStoreComponent implements OnInit {
  public isHidden$: Observable<boolean>;
  public isClosed: boolean;
  public sidebarItems: Array<SidebarItemModel>;
  public isBpFlowEnabled: boolean;
  public userRoleMenuTabName: typeof UserRoleMenuTabNameEnum;
  public isTutorialModalOpen: boolean;

  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    private noFunctionalityService: NoFunctionalityService,
    private modalService: ModalService,
    private currentUserService: CurrentUserService
  ) {
    super();

    this.isClosed = false;
    this.sidebarItems = this.sidebarService.getSidebarItems();
    this.isHidden$ = this.sidebarService.getHiddenState();
    this.isBpFlowEnabled = true;
    this.userRoleMenuTabName = UserRoleMenuTabNameEnum;
    this.isTutorialModalOpen = false;
  }

  public ngOnInit(): void {
    this.initActiveRouteListener();
    this.initIsBpFlowEnabled();
  }

  public toggleSidebar(): void {
    this.isClosed = !this.isClosed;
  }

  public openTutorialModal(): void {
    this.isTutorialModalOpen = true;

    const modalRef: NgbModalRef = this.modalService.openModal(TutorialModalComponent, ModalSizeEnum.large);

    this.subscription = from(modalRef.result).subscribe({
      next: () => {
        this.isTutorialModalOpen = false;
      },
    });
  }

  public redirectToPageOrOpenModal(event: Event, item: SidebarItemModel): void {
    event.preventDefault();
    event.stopPropagation();

    if (!item.disabled) {
      this.router.navigate([item.url]);

      return;
    }

    this.noFunctionalityService.openNoFunctionalityModal().pipe(take(1)).subscribe();
  }

  private selectActiveSidebarItem(): void {
    this.sidebarItems.forEach((sidebarItem: SidebarItemModel) => {
      sidebarItem.isActive = this.isSidebarItemActive(sidebarItem);
    });
  }

  private isSidebarItemActive(sidebarItem: SidebarItemModel): boolean {
    const url: string = this.getUrlWithoutParams();

    const matchedSidebarItems: Array<string> = sidebarItem.routeActivationKeys.filter((key: string) => {
      if (key === '') {
        return url === '/';
      }

      return url.split('/')[1] === key;
    });

    return matchedSidebarItems.length > 0;
  }

  private initActiveRouteListener(): void {
    this.selectActiveSidebarItem();

    this.router.events.pipe(filter((event: unknown) => event instanceof NavigationEnd)).subscribe(() => {
      this.selectActiveSidebarItem();
    });
  }

  private getUrlWithoutParams(): string {
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);

    urlTree.queryParams = {};
    urlTree.fragment = null;

    return urlTree.toString();
  }

  private initIsBpFlowEnabled(): void {
    this.subscription = this.currentUserService.getCurrentUser().subscribe((currentUser: CurrentUserModel | null) => {
      if (!currentUser) {
        return;
      }

      this.isBpFlowEnabled = currentUser.isBpFlowIntegrationEnabled;
    });
  }
}
