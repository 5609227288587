import { Component, Input } from '@angular/core';

import { StatusDeviceType } from '../../models/status-device-type/status-device.type';

@Component({
  selector: 'app-device-connection-status-icon[deviceType]',
  templateUrl: './device-connection-status-icon.component.html',
  styleUrls: ['./device-connection-status-icon.component.scss'],
})
export class DeviceConnectionStatusIconComponent {
  @Input() public deviceType!: StatusDeviceType;
  @Input() public isConnected!: boolean;

  public getDeviceIconCssClass(): string {
    switch (this.deviceType) {
      case 'spectrophotometer':
        return 'spectrophotometer-gray-24x24-icon';
      case 'scale':
        return 'scale-gray-24x24-icon';
      case 'barcodeScanner':
        return 'barcode-scanner-gray-24x24-icon';
      default:
        return '';
    }
  }
}
