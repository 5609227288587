export enum UserRoleMenuTabNameEnum {
  dashboard = 'dashboard',
  contractors = 'contractors',
  repairs = 'repairs',
  mixer = 'mixer',
  primers = 'primers',
  clearcoats = 'clearcoats',
  settings = 'settings',
  workshopManagement = 'workshop_management',
  priceList = 'price_list',
  statistics = 'statistics',
  colorIssues = 'color_issues',
  tutorials = 'tutorials',
}
