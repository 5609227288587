import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRoleMenuTabNameEnum } from '../../../user-roles/enums/user-role-menu-tab-name/user-role-menu-tab-name.enum';
import { SidebarItemModel } from '../../models/sidebar-item/sidebar-item.model';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private isVisible$: BehaviorSubject<boolean>;

  constructor() {
    this.isVisible$ = new BehaviorSubject<boolean>(true);
  }

  public showSidebar(): void {
    this.isVisible$.next(true);
  }

  public hideSidebar(): void {
    this.isVisible$.next(false);
  }

  public getHiddenState(): Observable<boolean> {
    return this.isVisible$.asObservable().pipe(map((isVisible: boolean) => !isVisible));
  }

  public getVisibilityState(): Observable<boolean> {
    return this.isVisible$.asObservable();
  }

  public getSidebarItems(): Array<SidebarItemModel> {
    return [
      {
        url: '/',
        label: 'layout.sidebar.menu.dashboard',
        iconClass: 'home-gray-icon',
        exact: true,
        routeActivationKeys: [''],
        isActive: false,
        name: UserRoleMenuTabNameEnum.dashboard,
        isEnabledWithBpFlow: true,
      },
      {
        url: '/contractors',
        label: 'layout.sidebar.menu.contractors',
        iconClass: 'contractor-gray-icon',
        routeActivationKeys: ['contractors'],
        exact: false,
        isActive: false,
        name: UserRoleMenuTabNameEnum.contractors,
        isEnabledWithBpFlow: false,
      },
      {
        url: '/fixes',
        label: 'layout.sidebar.menu.fixes',
        iconClass: 'fix-gray-icon',
        exact: false,
        isActive: false,
        routeActivationKeys: ['fixes'],
        name: UserRoleMenuTabNameEnum.repairs,
        isEnabledWithBpFlow: true,
      },
      {
        url: '/recipes',
        label: 'layout.sidebar.menu.recipes',
        iconClass: 'mix-gray-icon',
        exact: false,
        isActive: false,
        routeActivationKeys: ['recipes', 'mixes'],
        name: UserRoleMenuTabNameEnum.mixer,
        isEnabledWithBpFlow: true,
      },
      {
        url: '/primers',
        label: 'layout.sidebar.menu.primers',
        iconClass: 'addon-primer-gray-24x24-icon',
        exact: false,
        isActive: false,
        routeActivationKeys: ['primers'],
        name: UserRoleMenuTabNameEnum.primers,
        isEnabledWithBpFlow: true,
      },
      {
        url: '/clearcoats',
        label: 'layout.sidebar.menu.clearcoats',
        iconClass: 'addon-clearcoat-gray-24x24-icon',
        exact: false,
        isActive: false,
        routeActivationKeys: ['clearcoats'],
        name: UserRoleMenuTabNameEnum.clearcoats,
        isEnabledWithBpFlow: true,
      },
      {
        url: '/prices',
        label: 'layout.sidebar.menu.prices',
        iconClass: 'prices-gray-24x24-icon',
        exact: false,
        routeActivationKeys: ['prices'],
        isActive: false,
        name: UserRoleMenuTabNameEnum.priceList,
        isEnabledWithBpFlow: true,
      },
      {
        url: '/statistics',
        label: 'layout.sidebar.menu.statistics',
        iconClass: 'chart-gray-24x24-icon',
        exact: false,
        routeActivationKeys: ['statistics'],
        isActive: false,
        name: UserRoleMenuTabNameEnum.statistics,
        isEnabledWithBpFlow: true,
      },
      {
        url: '/color-issues',
        label: 'layout.sidebar.menu.colorIssues',
        iconClass: 'warning-octagon-gray-24x24-icon',
        exact: false,
        routeActivationKeys: ['color-issues'],
        isActive: false,
        name: UserRoleMenuTabNameEnum.colorIssues,
        isEnabledWithBpFlow: true,
      },
      {
        url: '/settings',
        label: 'layout.sidebar.menu.settings',
        iconClass: 'tab-gear-gray-icon',
        exact: false,
        routeActivationKeys: ['settings'],
        isActive: false,
        name: UserRoleMenuTabNameEnum.settings,
        isEnabledWithBpFlow: true,
      },
      {
        url: '/workshop',
        label: 'layout.sidebar.menu.workshop',
        iconClass: 'workshop-settings-gray-24x24-icon',
        exact: false,
        routeActivationKeys: ['workshop'],
        isActive: false,
        name: UserRoleMenuTabNameEnum.workshopManagement,
        isEnabledWithBpFlow: true,
      },
    ];
  }
}
