import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { CurrentUserService } from '../../../users/services/current-user/current-user.service';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { AuthUserService } from '../../../auth/services/auth-user/auth-user.service';
import { ModalService } from '../../../modal/services/modal.service';
import { CanDeactivateGuardModel } from '../../../shared/models/can-deactivate-guard/can-deactivate-guard.model';
import { LocationGetStateUtil } from '../../../shared/utils/location-get-state/location-get-state.util';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends SubscriptionStoreComponent implements OnInit {
  @ViewChild(NgbDropdown) public dropdown!: NgbDropdown;

  public currentUserUsername: string | null;

  constructor(
    private currentUserService: CurrentUserService,
    private authUserService: AuthUserService,
    private router: Router,
    private modalService: ModalService
  ) {
    super();
    this.currentUserUsername = null;
  }

  public ngOnInit(): void {
    this.initCurrentUser();
  }

  public logoutEvent(): void {
    this.subscription = this.modalService
      .openPopupModal(
        'layout.header.logoutPopup.titleKey',
        'layout.header.logoutPopup.textKey',
        'layout.header.logoutPopup.successButtonLabelKey',
        'layout.header.logoutPopup.cancelButtonLabelKey'
      )
      .subscribe((action: boolean) => {
        if (action) {
          this.logout();
        }
      });
  }

  public navigateToProfile(): void {
    this.navigate('/profile', false);

    this.dropdown.close();
  }

  public navigateToDashboard(): void {
    this.navigate('/', false);
  }

  private logout(): void {
    this.subscription = this.authUserService.logoutFromApiAndApplication().subscribe(() => {
      this.navigateToLoginPage();
    });
  }

  private navigateToLoginPage(): void {
    this.navigate('/auth/login', true);
  }

  private navigate(url: string, canDeactivate: boolean): void {
    this.router.navigate([url], { state: LocationGetStateUtil.createState(this.getCanDeactivateGuardState(canDeactivate)) });
  }

  private initCurrentUser(): void {
    this.subscription = this.currentUserService.getCurrentUserUsername().subscribe((username: string | null) => {
      this.currentUserUsername = username;
    });
  }

  private getCanDeactivateGuardState(canDeactivate: boolean): CanDeactivateGuardModel<Record<string, never>> {
    return {
      canDeactivate,
    };
  }
}
