import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { LanguageDto } from '../../dtos/language/language.dto';
import { ListDto } from '../../../shared/dtos/list/list.dto';

@Injectable({
  providedIn: 'root',
})
export class LanguageHttpService {
  constructor(private httpClient: HttpClient) {}

  public getLanguageList(): Observable<ListDto<LanguageDto>> {
    return this.httpClient
      .get<ApiResponseDto<ListDto<LanguageDto>>>(ApiUrlUtil.getApiUrl('language/language'))
      .pipe(map((response: ApiResponseDto<ListDto<LanguageDto>>) => response.data));
  }
}
