import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TutorialsService } from '../../service/tutorials/tutorials.service';
import { TutorialItemModel } from '../../models/tutorial-item/tutorial-item.model';
import { CurrentLanguageService } from '../../../i18n/services/current-language/current-language.service';
import { TutorialTypeEnum } from '../../enums/tutorial-type/tutorial-type.enum';

@Component({
  selector: 'app-tutorial-item-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrls: ['./tutorial-modal.component.scss'],
})
export class TutorialModalComponent {
  public tutorialItems: Array<TutorialItemModel>;
  public selectedTutorialItemIndex: number;
  public tutorialType: typeof TutorialTypeEnum;

  private readonly language: string;

  constructor(
    private activeModal: NgbActiveModal,
    private tutorialsService: TutorialsService,
    private currentLanguageService: CurrentLanguageService
  ) {
    this.selectedTutorialItemIndex = 0;
    this.language = this.currentLanguageService.getCurrentLanguage();
    this.tutorialItems = this.tutorialsService.getTutorialItemsByLanguage(this.language);
    this.tutorialType = TutorialTypeEnum;
  }

  public close(): void {
    this.activeModal.close();
  }

  public setSelectTutorialItemIndex(index: number): void {
    this.selectedTutorialItemIndex = index;
  }
}
