import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { CurrentConnectionService } from '../../services/current-connection/current-connection.service';

@Component({
  selector: 'app-device-connection-status-container',
  templateUrl: './device-connection-status-container.component.html',
  styleUrls: ['./device-connection-status-container.component.scss'],
})
export class DeviceConnectionStatusContainerComponent {
  public spectrophotometerIsConnected$: Observable<boolean>;
  public scaleIsConnected$: Observable<boolean>;
  public barcodeScannerIsConnected$: Observable<boolean>;

  constructor(private currentConnectionService: CurrentConnectionService) {
    this.spectrophotometerIsConnected$ = this.currentConnectionService.spectrophotometerDevice.isSpectrophotometerConnected$;
    this.scaleIsConnected$ = this.currentConnectionService.scaleDevice.isDeviceConnected$;
    this.barcodeScannerIsConnected$ = this.currentConnectionService.barcodeScannerDevice.isDeviceConnected$;
  }
}
