import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { TutorialItemModel } from '../../models/tutorial-item/tutorial-item.model';
import { TutorialTypeEnum } from '../../enums/tutorial-type/tutorial-type.enum';
import { TutorialDataItemModel } from '../../models/tutorial-data-item/tutorial-data-item.model';
import { TutorialSrcModel } from '../../models/tutorial-src/tutorial-src.model';

@Injectable({
  providedIn: 'root',
})
export class TutorialsService {
  private supportedLanguages: Array<string>;

  constructor(private sanitizer: DomSanitizer) {
    this.supportedLanguages = ['PL', 'EN'];
  }

  public getTutorialItemsByLanguage(language: string): Array<TutorialItemModel> {
    if (this.supportedLanguages.findIndex((supportedLanguage: string) => supportedLanguage === language) === -1) {
      language = 'EN';
    }

    return this.getTutorialData().map((tutorialDataItem: TutorialDataItemModel) => {
      const tutorialSrc: TutorialSrcModel = tutorialDataItem.src.find(
        (src: TutorialSrcModel) => src.language === language
      ) as TutorialSrcModel;

      return {
        title: tutorialDataItem.title,
        src: this.sanitizer.bypassSecurityTrustResourceUrl(tutorialSrc.src),
        type: tutorialDataItem.type,
      };
    });
  }

  private getTutorialData(): Array<TutorialDataItemModel> {
    return [
      {
        title: 'tutorials.tutorialItem.title.installation',
        type: TutorialTypeEnum.video,
        src: [
          {
            language: 'PL',
            src: 'https://www.youtube.com/embed/1huqsBtv9PU',
          },
          {
            language: 'EN',
            src: 'https://www.youtube.com/embed/hKEKoBUk5ZU',
          },
        ],
      },
      {
        title: 'tutorials.tutorialItem.title.settings',
        type: TutorialTypeEnum.video,
        src: [
          {
            language: 'PL',
            src: 'https://www.youtube.com/embed/QsFPT70BgiU',
          },
          {
            language: 'EN',
            src: 'https://www.youtube.com/embed/jBR3YxGz5dc',
          },
        ],
      },
      {
        title: 'tutorials.tutorialItem.title.weights',
        type: TutorialTypeEnum.image,
        src: [
          {
            language: 'PL',
            src: '/assets/images/tutorials/weights.png',
          },
          {
            language: 'EN',
            src: '/assets/images/tutorials/weights.png',
          },
        ],
      },
      {
        title: 'tutorials.tutorialItem.title.weightSettings',
        type: TutorialTypeEnum.video,
        src: [
          {
            language: 'PL',
            src: 'https://www.youtube.com/embed/NFGsldVnDMw',
          },
          {
            language: 'EN',
            src: 'https://www.youtube.com/embed/_9D5REeVqnY',
          },
        ],
      },
      {
        title: 'tutorials.tutorialItem.title.scanners',
        type: TutorialTypeEnum.image,
        src: [
          {
            language: 'PL',
            src: '/assets/images/tutorials/scanners.png',
          },
          {
            language: 'EN',
            src: '/assets/images/tutorials/scanners.png',
          },
        ],
      },
      {
        title: 'tutorials.tutorialItem.title.scannerConnection',
        type: TutorialTypeEnum.video,
        src: [
          {
            language: 'PL',
            src: 'https://www.youtube.com/embed/GzU-H1aEQt0',
          },
          {
            language: 'EN',
            src: 'https://www.youtube.com/embed/sstIzyPkVKM',
          },
        ],
      },
      {
        title: 'tutorials.tutorialItem.title.spectrophotometers',
        type: TutorialTypeEnum.image,
        src: [
          {
            language: 'PL',
            src: '/assets/images/tutorials/spectrophotometers.png',
          },
          {
            language: 'EN',
            src: '/assets/images/tutorials/spectrophotometers.png',
          },
        ],
      },
      {
        title: 'tutorials.tutorialItem.title.spectrophotometerConnection',
        type: TutorialTypeEnum.video,
        src: [
          {
            language: 'PL',
            src: 'https://www.youtube.com/embed/TqYEEmOjA4c',
          },
          {
            language: 'EN',
            src: 'https://www.youtube.com/embed/-pKjfgmAztk',
          },
        ],
      },
      {
        title: 'tutorials.tutorialItem.title.printers',
        type: TutorialTypeEnum.image,
        src: [
          {
            language: 'PL',
            src: '/assets/images/tutorials/printers.png',
          },
          {
            language: 'EN',
            src: '/assets/images/tutorials/printers.png',
          },
        ],
      },
      {
        title: 'tutorials.tutorialItem.title.printerConnection',
        type: TutorialTypeEnum.video,
        src: [
          {
            language: 'PL',
            src: 'https://www.youtube.com/embed/_ETmd_vtk4M',
          },
          {
            language: 'EN',
            src: 'https://www.youtube.com/embed/Yd0ExS5N7FE',
          },
        ],
      },
    ];
  }
}
