<app-modal [hasContentSidePadding]="false" [hasContentVerticalPadding]="false">
  <div class="modal-content" modal-content>
    <div class="menu">
      <div class="item title">
        {{ 'tutorials.tutorialModal.title' | translate }}
      </div>

      <ng-scrollbar class="menu-scrollbar" [autoHeightDisabled]="true">
        <div
          class="item"
          [class.active]="selectedTutorialItemIndex === i"
          *ngFor="let tutorialItem of tutorialItems; let i = index"
          (click)="setSelectTutorialItemIndex(i)"
        >
          {{ tutorialItem.title | translate }}
        </div>
      </ng-scrollbar>
    </div>

    <div class="content">
      <ng-container [ngSwitch]="tutorialItems[selectedTutorialItemIndex].type">
        <iframe
          type="text/html"
          width="919"
          height="516"
          allowfullscreen
          [src]="tutorialItems[selectedTutorialItemIndex].src"
          frameborder="0"
          *ngSwitchCase="tutorialType.video"
        ></iframe>

        <img class="image" [src]="tutorialItems[selectedTutorialItemIndex].src" alt="tutorial item" *ngSwitchCase="tutorialType.image" />
      </ng-container>

      <div class="footer">
        <button class="button red" (click)="close()">
          {{ 'tutorials.tutorialModal.closeButton' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal>
