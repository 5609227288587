import { LanguageDto } from '../../dtos/language/language.dto';
import { LanguageModel } from '../../models/language/language.model';

export class LanguageCreator {
  public static create(dto: LanguageDto): LanguageModel {
    return {
      code: dto.code,
      name: dto.name,
    };
  }
}
