<div class="device-connection-status-container">
  <app-device-connection-status-icon
    class="connection-status-icon"
    deviceType="barcodeScanner"
    [isConnected]="(barcodeScannerIsConnected$ | async) || false"
  ></app-device-connection-status-icon>
  <app-device-connection-status-icon
    class="connection-status-icon"
    deviceType="scale"
    [isConnected]="(scaleIsConnected$ | async) || false"
  ></app-device-connection-status-icon>
  <app-device-connection-status-icon
    class="connection-status-icon"
    deviceType="spectrophotometer"
    [isConnected]="(spectrophotometerIsConnected$ | async) || false"
  ></app-device-connection-status-icon>
</div>
